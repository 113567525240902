import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/operation',
    name: 'Operation',
    component: Layout,
    redirect: '/operation/version',
    meta: { title: '运营管理', icon: 'server', orderNo: 2 },
    children: [
      {
        path: 'newsCenter',
        name: 'OperationNewsCenter',
        component: () => import('@/pages/operation/News.vue'),
        meta: { title: '新闻中心' },
      },
      {
        path: 'consult',
        name: 'OperationConsult',
        component: () => import('@/pages/operation/Consult.vue'),
        meta: { title: '咨询名单' },
      },
      {
        path: 'redeemCode',
        name: 'OperationRedeemCode',
        component: () => import('@/pages/operation/RedeemCode.vue'),
        meta: { title: '兑换码' },
      },
      {
        path: 'redeemCode/:id',
        name: 'OperationRedeemCodeDetail',
        component: () => import('@/pages/operation/RedeemCodeDetail.vue'),
        meta: { title: '查看兑换码', hidden: true },
      },
      {
        path: 'billingScheme',
        name: 'OperationBillingScheme',
        component: () => import('@/pages/operation/BillingScheme.vue'),
        meta: { title: '计费方案' },
      },
      {
        path: 'billingSchemeEditor/:id',
        name: 'OperationBillingSchemeEditor',
        component: () => import('@/pages/operation/BillingSchemeEditor.vue'),
        meta: { title: '计费方案编辑', hidden: true },
      },
      {
        path: 'tokenPackage',
        name: 'OperationTokenPackage',
        component: () => import('@/pages/operation/TokenPackage.vue'),
        meta: { title: 'token套餐包' },
      },
      {
        path: 'tokenPackageEditor/:id',
        name: 'OperationTokenPackageEditor',
        component: () => import('@/pages/operation/TokenPackageEditor.vue'),
        meta: { title: 'token套餐包编辑', hidden: true },
      },
    ],
  },
];
