import { defineStore } from 'pinia';

import { asyncRouterList } from '@/router';
import { MenuRoute } from '@/types/interface';

import { useUserStore } from './user';

export const usePermissionStore = defineStore('permission', {
  state: () => ({
    whiteListRouters: ['/login', '/noAccess'],
  }),
  getters: {
    /**
     * 菜单
     */
    menus: () => {
      const userStore = useUserStore();
      const res: MenuRoute[] = [];
      asyncRouterList.forEach((route) => {
        const children: MenuRoute[] = [];
        route.children?.forEach((childRouter) => {
          if (childRouter.meta.withoutAuth || userStore.permissions.indexOf(childRouter.name as string) !== -1) {
            children.push(childRouter as MenuRoute);
          }
        });
        if (children.length > 0) {
          route.children = children;
          res.push(route as MenuRoute);
        }
      });

      return res;
    },
  },
});
