import { RequestOptions } from '@meerkat/utils';

import { PageSearchParams, PaginationData } from '@/types/interface';
import { request } from '@/utils/request';

import {
  BaseUserInfo,
  ChangePasswordParams,
  LoginParams,
  LoginRes,
  PhoneLoginParams,
  Salesperson,
  SalespersonParams,
  SystemRole,
  SystemRoleParams,
  SystemUser,
  SystemUserParams,
} from './model/systemModel';

/**
 * ====================
 *       登录/账户
 * ====================
 */
/**
 * 进行登录
 * @param params 登录参数
 * @param options 请求配置
 */
export function doLogin(params: LoginParams, options?: RequestOptions) {
  return request.post({ url: '/login', data: params }, options);
}

/**
 * 获取图形验证码
 * @param options 请求配置
 */
export function getCaptchaCode(options?: RequestOptions) {
  return request.get({ url: '/sendImageCode' }, options);
}

/**
 * 进行手机号登录
 * @param params 登录参数
 * @param options 请求配置
 */
export function doMobileLogin(params: PhoneLoginParams, options?: RequestOptions) {
  return request.post<LoginRes>({ url: '/mobileLogin', data: params }, options);
}

/**
 * 修改密码
 * @param params 请求参数
 * @param options 请求配置
 */
export function changePassword(params: ChangePasswordParams, options?: RequestOptions) {
  return request.post({ url: '/account/updatePassword', data: params }, options);
}

/**
 * 获取的登录账号信息
 * @param options 请求配置
 */
export function getUserInfo(options?: RequestOptions) {
  return request.get<BaseUserInfo>({ url: '/userInfo' }, options);
}

/**
 * ====================
 *       账户管理
 * ====================
 */
/**
 * 新增账户
 * @param data 请求参数
 * @param options 请求配置
 */
export function addAccount(data: SystemUserParams, options?: RequestOptions) {
  return request.post({ url: '/account/add', data }, options);
}

/**
 * 编辑账户
 * @param data 请求参数
 * @param options 请求配置
 */
export function editAccount(data: SystemUserParams, options?: RequestOptions) {
  return request.post({ url: '/account/update', data }, options);
}

/**
 * 切换账户状态
 * @param id ID
 * @param options 请求配置
 */
export function changeAccountStatus(id: number, options?: RequestOptions) {
  return request.post({ url: `/account/updateState/${id}` }, { loading: true, ...options });
}

/**
 * 获取账户列表
 * @param params 请求参数
 * @param options 请求配置
 */
export function getAccountList(params: PageSearchParams, options?: RequestOptions) {
  return request.get<PaginationData<SystemUser>>({ url: '/account/list', params }, options);
}

/**
 * ====================
 *       角色管理
 * ====================
 */
/**
 * 获取全部系统菜单/权限
 * @param options 请求配置
 */
export function getAllSystemMenu(options?: RequestOptions) {
  return request.get<SystemRole[]>({ url: '/bRole/queryMenuTree' }, options);
}

/**
 * 新增角色
 * @param data 请求参数
 * @param options 请求配置
 */
export function addRole(data: SystemRoleParams, options?: RequestOptions) {
  return request.post({ url: '/bRole/addRole', data }, options);
}

/**
 * 编辑角色
 * @param data 请求参数
 * @param options 请求配置
 */
export function editRole(data: SystemRoleParams, options?: RequestOptions) {
  return request.post({ url: '/bRole/updateRole', data }, options);
}

/**
 * 删除角色
 * @param id ID
 * @param options 请求配置
 */
export function delRole(id: number, options?: RequestOptions) {
  return request.delete({ url: `/bRole/deleteById/${id}` }, { loading: true, ...options });
}

/**
 * 获取角色列表
 * @param params 请求参数
 * @param options 请求配置
 */
export function getRoleList(params: PageSearchParams, options?: RequestOptions) {
  return request.get<PaginationData<SystemRole>>({ url: '/bRole/list', params }, options);
}

/**
 * 获取全部角色列表
 * @param options 请求配置
 */
export function getAllRoleList(options?: RequestOptions) {
  return request.get<SystemRole[]>({ url: '/bRole/queryAllRoles' }, options);
}

/**
 * 获取角色信息
 * @param id ID
 * @param options 请求配置
 */
export function getRole(id: number, options?: RequestOptions) {
  return request.get<SystemRole>({ url: `/bRole/getRoleById/${id}` }, { loading: true, ...options });
}

/**
 * ====================
 *       销售人员
 * ====================
 */
/**
 * 新增销售人员
 * @param data 请求参数
 * @param options 请求配置
 */
export function addSalesperson(data: SalespersonParams, options?: RequestOptions) {
  return request.post({ url: '/sale/add', data }, options);
}

/**
 * 编辑销售人员
 * @param data 请求参数
 * @param options 请求配置
 */
export function editSalesperson(data: SalespersonParams, options?: RequestOptions) {
  return request.put({ url: '/sale/update', data }, options);
}

/**
 * 删除销售人员
 * @param id ID
 * @param options 请求配置
 */
export function deleteSalesperson(id: number, options?: RequestOptions) {
  return request.delete({ url: `/sale/delete/${id}` }, { loading: true, ...options });
}

/**
 * 获取销售人员列表
 * @param params 请求参数
 * @param options 请求配置
 */
export function getSalespersonList(params: PageSearchParams, options?: RequestOptions) {
  return request.get<PaginationData<Salesperson>>({ url: '/sale/list', params }, options);
}

/**
 * 获取全部销售人员
 * @param options 请求配置
 */
export function getAllSalesperson(options?: RequestOptions) {
  return request.get<Salesperson[]>({ url: '/sale/queryAll' }, options);
}
