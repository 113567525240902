<template>
  <div :class="sideNavCls">
    <t-menu
      :class="menuCls"
      :value="active"
      :collapsed="collapsed"
      :default-expanded="
        getKeyPath({
          tree: props.menu,
          value: active,
          key: 'name',
          children: 'children',
        }) as unknown as string[]
      "
    >
      <template #logo>
        <span :class="`${prefix}-side-nav-logo-wrapper`" @click="goHome">
          <component :is="getLogo()" :class="`${prefix}-side-nav-logo-${collapsed ? 't' : 'tdesign'}-logo`" />
        </span>
      </template>
      <menu-content :nav-data="menu" />
    </t-menu>
    <div :class="`${prefix}-side-nav-placeholder${collapsed ? '-hidden' : ''}`"></div>
  </div>
</template>

<script setup lang="ts">
import { getKeyPath } from '@meerkat/utils';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import AssetLogoFull from '@/assets/assets-logo-full.svg?component';
import AssetLogo from '@/assets/assets-t-logo.svg?component';
import { prefix } from '@/config/global';
import { useSettingStore } from '@/store';
import { MenuRoute } from '@/types/interface';

import MenuContent from './MenuContent.vue';

const MIN_POINT = 992 - 1;

const route = useRoute();
const props = defineProps<{
  /**
   * 是否收起菜单
   */
  isCompact: boolean;
  /**
   * 菜单
   */
  menu: MenuRoute[];
}>();

const collapsed = computed(() => useSettingStore().isSidebarCompact);

const active = computed(() => {
  const obPath = getKeyPath({
    tree: props.menu,
    value: route.name,
    key: 'name',
    children: 'children',
    valueType: 'object',
  }) as MenuRoute[];

  for (const item of obPath) {
    if (item.meta && item.meta.single) {
      return item.name;
    }
  }

  return route.name as string;
});

const sideNavCls = computed(() => {
  const { isCompact } = props;
  return [
    `${prefix}-sidebar-layout`,
    {
      [`${prefix}-sidebar-compact`]: isCompact,
    },
  ];
});

const menuCls = computed(() => {
  return [`${prefix}-side-nav`];
});

const router = useRouter();
const settingStore = useSettingStore();

const autoCollapsed = () => {
  const isCompact = window.innerWidth <= MIN_POINT;
  settingStore.updateConfig({
    isSidebarCompact: isCompact,
  });
};

onMounted(() => {
  autoCollapsed();
  window.onresize = () => {
    autoCollapsed();
  };
});

const goHome = () => {
  router.push('/dashboard');
};

const getLogo = () => {
  if (collapsed.value) return AssetLogo;
  return AssetLogoFull;
};
</script>

<style lang="less" scoped></style>
