<template>
  <div :class="layoutCls">
    <t-head-menu :class="menuCls" expand-type="popup">
      <template #logo>
        <div class="header-operate-left">
          <t-button theme="default" shape="square" variant="text" @click="changeCollapsed">
            <view-list-icon />
          </t-button>
        </div>
      </template>
      <template #operations>
        <div class="operations-container">
          <t-dropdown :min-column-width="135" trigger="click">
            <template #dropdown>
              <t-dropdown-menu>
                <t-dropdown-item class="operations-dropdown-container-item" @click="toChangePassword()">
                  <user-password-icon />修改密码
                </t-dropdown-item>
                <t-dropdown-item class="operations-dropdown-container-item" @click="handleLogout">
                  <poweroff-icon />退出登录
                </t-dropdown-item>
              </t-dropdown-menu>
            </template>
            <t-button class="header-user-btn" theme="default" variant="text">
              <template #icon>
                <user-circle-icon />
              </template>
              <div class="header-user-account">
                {{ userStore.name }}
                <chevron-down-icon />
              </div>
            </t-button>
          </t-dropdown>
        </div>
      </template>
    </t-head-menu>
  </div>

  <!-- 修改密码 -->
  <t-dialog
    v-model:visible="onChangePassword"
    header="修改密码"
    destroy-on-close
    :close-on-esc-keydown="!changePasswordSubmitting"
    :close-btn="!changePasswordSubmitting"
    :cancel-btn="{ disabled: changePasswordSubmitting }"
    :confirm-btn="{ loading: changePasswordSubmitting }"
    @confirm="() => changePasswordFormRef.submit()"
  >
    <t-form
      ref="changePasswordFormRef"
      :data="changePasswordForm"
      :required-mark="false"
      :rules="changePasswordFormRules"
      :disabled="changePasswordSubmitting"
      @submit="submitChangePassword"
    >
      <t-form-item label="旧密码" name="oldPassword">
        <t-input v-model="changePasswordForm.oldPassword" placeholder="旧密码" type="password" />
      </t-form-item>
      <t-form-item label="新密码" name="newPassword">
        <t-input v-model="changePasswordForm.newPassword" placeholder="密码" type="password" />
      </t-form-item>
      <t-form-item label="重复密码" name="password">
        <t-input v-model="changePasswordForm.password" placeholder="重复密码" type="password" />
      </t-form-item>
      <div />
    </t-form>
  </t-dialog>
</template>

<script setup lang="ts">
import { passwordReg } from '@meerkat/common/pattern';
import { useEditForm } from '@meerkat/hooks';
import { cloneDeep } from 'lodash-es';
import { ChevronDownIcon, PoweroffIcon, UserCircleIcon, UserPasswordIcon, ViewListIcon } from 'tdesign-icons-vue-next';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { ChangePasswordParams } from '@/api/model/systemModel';
import { changePassword } from '@/api/system';
import { prefix } from '@/config/global';
import { useSettingStore, useUserStore } from '@/store';

const userStore = useUserStore();

const props = defineProps<{
  /**
   * 是否收起菜单
   */
  isCompact: boolean;
}>();

const router = useRouter();
const settingStore = useSettingStore();

const layoutCls = computed(() => [`${prefix}-header-layout`]);

const menuCls = computed(() => {
  return [
    `${prefix}-header-menu-fixed`,
    `${prefix}-header-menu-fixed-side`,
    {
      [`${prefix}-header-menu-fixed-side-compact`]: props.isCompact,
    },
  ];
});

const changeCollapsed = () => {
  settingStore.updateConfig({
    isSidebarCompact: !settingStore.isSidebarCompact,
  });
};

const handleLogout = () => {
  router.push({
    path: '/login',
    query: { redirect: encodeURIComponent(router.currentRoute.value.fullPath) },
  });
};

/**
 * ====================
 *       修改密码
 * ====================
 */
const {
  formRef: changePasswordFormRef,
  onEdit: onChangePassword,
  formData: changePasswordForm,
  formRules: changePasswordFormRules,
  submitting: changePasswordSubmitting,
  toEdit: toChangePassword,
  submit: submitChangePassword,
} = useEditForm<ChangePasswordParams>({
  default: {
    oldPassword: '',
    newPassword: '',
    password: '',
  },
  formRules: {
    oldPassword: [
      { required: true, message: '请输入旧密码', type: 'error' },
      { pattern: passwordReg, message: '6~16位密码，支持字母、数字以及_!@#$%^&*`~()-+=', trigger: 'blur' },
    ],
    newPassword: [
      { required: true, message: '请输入新密码', type: 'error' },
      { pattern: passwordReg, message: '6~16位密码，支持字母、数字以及_!@#$%^&*`~()-+=', trigger: 'blur' },
    ],
    password: [
      { required: true, message: '请输入重复密码', type: 'error' },
      { pattern: passwordReg, message: '6~16位密码，支持字母、数字以及_!@#$%^&*`~()-+=', trigger: 'blur' },
      {
        validator: (val) => {
          const data = cloneDeep(changePasswordForm.value) as ChangePasswordParams;
          return data.newPassword === val;
        },
        message: '必须要和新密码一致',
        type: 'error',
      },
    ],
  },
  onSubmit: (data) => {
    return changePassword(data);
  },
  onSuccess: () => {
    MessagePlugin.success('密码修改成功');
  },
});
</script>

<style lang="less" scoped>
.@{starter-prefix}-header {
  &-layout {
    height: 64px;
  }

  &-menu-fixed {
    position: fixed;
    top: 0;
    z-index: 1001;

    &-side {
      left: 232px;
      right: 0;
      z-index: 10;
      width: auto;
      transition: all 0.3s;
      &-compact {
        left: 64px;
      }
    }
  }

  &-logo-container {
    cursor: pointer;
    display: inline-flex;
    height: 64px;
  }
}
.header-menu {
  flex: 1 1 1;
  display: inline-flex;

  :deep(.t-menu__item) {
    min-width: unset;
    padding: 0px 16px;
  }
}

.operations-container {
  display: flex;
  align-items: center;
  margin-right: 12px;

  .t-popup__reference {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .t-button {
    margin: 0 8px;
    &.header-user-btn {
      margin: 0;
    }
  }

  .t-icon {
    font-size: 20px;
    &.general {
      margin-right: 16px;
    }
  }
}

.header-operate-left {
  display: flex;
  margin-left: 20px;
  align-items: normal;
  line-height: 0;

  .collapsed-icon {
    font-size: 20px;
  }
}

.header-logo-container {
  width: 184px;
  height: 26px;
  display: flex;
  margin-left: 24px;
  color: var(--td-text-color-primary);

  .t-logo {
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.header-user-account {
  display: inline-flex;
  align-items: center;
  color: var(--td-text-color-primary);
  .t-icon {
    margin-left: 4px;
    font-size: 16px;
  }
}

:deep(.t-head-menu__inner) {
  border-bottom: 1px solid var(--td-border-level-1-color);
}

.t-menu--light {
  .header-user-account {
    color: var(--td-text-color-primary);
  }
}
.t-menu--dark {
  .t-head-menu__inner {
    border-bottom: 1px solid var(--td-gray-color-10);
  }
  .header-user-account {
    color: rgba(255, 255, 255, 0.55);
  }
  .t-button {
    --ripple-color: var(--td-gray-color-10) !important;
    &:hover {
      background: var(--td-gray-color-12) !important;
    }
  }
}

.operations-dropdown-container-item {
  width: 100%;
  display: flex;
  align-items: center;

  .t-icon {
    margin-right: 8px;
  }

  :deep(.t-dropdown__item) {
    .t-dropdown__item__content {
      display: flex;
      justify-content: center;
    }
    .t-dropdown__item__content__text {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }

  :deep(.t-dropdown__item) {
    width: 100%;
    margin-bottom: 0px;
  }
  &:last-child {
    :deep(.t-dropdown__item) {
      margin-bottom: 8px;
    }
  }
}
</style>
