import 'dayjs/locale/zh-cn';
import 'dayjs/locale/ko';

import { defineStore } from 'pinia';

// 通用仓库接口
interface GeneralStore {
  fullLoading: boolean;
  fullLoadingTip: string;
}

export const useGeneralStore = defineStore({
  id: 'general',
  state: () =>
    <GeneralStore>{
      // 全屏加载
      fullLoading: false,
      fullLoadingTip: '',
    },
  actions: {
    /**
     * 开始全屏加载动画
     *
     * @param tip 提示文本
     */
    loading(tip = '') {
      this.fullLoading = true;
      this.fullLoadingTip = tip;
    },
    /**
     * 关闭全屏加载
     */
    closeLoading() {
      this.fullLoading = false;
    },
  },
});
