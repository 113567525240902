<template>
  <div>
    <template v-for="item in list" :key="item.path">
      <t-menu-item
        v-if="!item.children || !item.children.length || item.meta?.single"
        :name="item.name"
        :value="item.name"
        :to="item.path"
      >
        <template #icon>
          <t-icon v-if="beIcon(item)" :name="item.icon as string" />
          <component :is="beRender(item).render" v-else-if="beRender(item).can" class="t-icon" />
        </template>
        {{ item.title }}
      </t-menu-item>
      <t-submenu v-else :name="item.name" :value="item.name" :title="item.title">
        <template #icon>
          <t-icon v-if="beIcon(item)" :name="item.icon as string" />
          <component :is="beRender(item).render" v-else-if="beRender(item).can" class="t-icon" />
        </template>
        <menu-content v-if="item.children" :nav-data="item.children" />
      </t-submenu>
    </template>
  </div>
</template>

<script setup lang="ts">
import { isObject } from 'lodash-es';
import { computed } from 'vue';

import { MenuRoute } from '@/types/interface';

const props = defineProps<{
  /**
   * 导航数据
   */
  navData: MenuRoute[];
}>();

const list = computed(() => {
  const { navData } = props;
  return getMenuList(navData);
});

const getMenuList = (list: MenuRoute[], basePath?: string): MenuRoute[] => {
  if (!list) {
    return [];
  }
  // 如果meta中有orderNo则按照从小到大排序
  list.sort((a, b) => {
    return (a.meta?.orderNo || 0) - (b.meta?.orderNo || 0);
  });
  return list
    .map((item) => {
      const path = basePath && !item.path.includes(basePath) ? `${basePath}/${item.path}` : item.path;
      return {
        path,
        title: item.meta?.title,
        icon: item.meta?.icon || '',
        children: getMenuList(item.children, path),
        meta: item.meta,
        name: item.name,
        redirect: item.redirect,
      };
    })
    .filter((item) => item.meta && item.meta.hidden !== true);
};

const beIcon = (item: MenuRoute) => {
  return item.icon && typeof item.icon === 'string';
};

const beRender = (item: MenuRoute) => {
  if (isObject(item.icon) && typeof item.icon.render === 'function') {
    return {
      can: true,
      render: item.icon.render,
    };
  }
  return {
    can: false,
    render: null,
  };
};
</script>

<style lang="less" scoped></style>
