import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/',
    component: Layout,
    name: 'Base',
    redirect: '/dashboard',
    meta: { title: '仪表盘', icon: 'dashboard', single: true, orderNo: 0 },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/pages/dashboard/index.vue'),
        meta: { title: '仪表盘', withoutAuth: true },
      },
      {
        path: 'noAccess',
        name: 'NoAccess',
        component: () => import('@/pages/NoAccess.vue'),
        meta: { title: '无权限', hidden: true },
      },
    ],
  },
];
