import { createAxios } from '@meerkat/utils';

import router from '@/router';
import { useUserStore } from '@/store';

export const request = createAxios({
  getToken: () => {
    const userStore = useUserStore();
    return userStore.token;
  },
  authInvalid() {
    router.replace({
      path: '/login',
      query: { redirect: encodeURIComponent(router.currentRoute.value.fullPath) },
    });
  },
  host: import.meta.env.VITE_API_URL,
  responseTransform: (data) => {
    return {
      code: data.status,
      data: data.data,
      msg: data.message,
    };
  },
});
