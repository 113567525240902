import 'nprogress/nprogress.css'; // progress bar style

import NProgress from 'nprogress'; // progress bar

import router from '@/router';
import { getUserStore, usePermissionStore } from '@/store';

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  if (to.query.title) {
    to.meta.title = `${to.query.title} - ${to.meta.title}`;
  }

  const userStore = getUserStore();
  const permissionStore = usePermissionStore();
  const { whiteListRouters } = permissionStore;

  // 进入登录页面不处理直接进入
  if (to.path === '/login') {
    next();
    return;
  }

  // 未登录但在白名单内直接进入
  if (whiteListRouters.includes(to.path)) {
    next();
    return;
  }

  // 未登录跳转登录页面（不在白名单）
  if (!userStore.token) {
    next({
      path: '/login',
      query: { redirect: encodeURIComponent(to.fullPath) },
    });
    return;
  }

  // 获取登录用户信息
  try {
    await userStore.getUserInfo();
  } catch (error) {
    MessagePlugin.warning(error.message);
    next({
      path: '/login',
      query: { redirect: encodeURIComponent(to.fullPath) },
    });
    return;
  }

  // 权限判断，如无权限则跳转无权限页面
  if (['/'].includes(to.path) && !userStore.permissions.includes(to.name as string)) {
    next('/noAccess');
    return;
  }

  // 404页面跳转由 router 接管，这里无需处理
  // 正常跳转
  next();
});

router.afterEach((to) => {
  if (to.path === '/login') {
    const userStore = getUserStore();

    userStore.logout();
  }
  NProgress.done();
});
