import { defineStore } from 'pinia';

import { store } from '@/store';

const state = {
  isSidebarCompact: false,
};

export type TState = typeof state;

export const useSettingStore = defineStore('setting', {
  state: () => state,
  actions: {
    updateConfig(payload: Partial<TState>) {
      type TStateKeys = keyof TState;
      for (const key in payload) {
        if (payload[key as TStateKeys] !== undefined) {
          this[key] = payload[key as TStateKeys];
        }
      }
    },
  },
  persist: true,
});

export function getSettingStore() {
  return useSettingStore(store);
}
