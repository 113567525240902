import { cloneDeep } from 'lodash-es';
import { FormInstanceFunctions, FormRules, SubmitContext } from 'tdesign-vue-next';
import { ref } from 'vue';

/**
 * 编辑钩子配置项
 */
export interface EditFormConfig<T, K, R> {
  /**
   * 初始表单数据
   */
  default: T;
  /**
   * 表单规则
   */
  formRules?: FormRules<T | R>;
  /**
   * 数据转换方法
   * @param data 待转换的数据
   * @describe 又是可能置入的数据与表单的结构并不相同，可能需要手动转换
   */
  dataTransform?: (data: K) => T;
  /**
   * 提交回调
   */
  onSubmit?: (data: T) => Promise<any>;
  /**
   * 提交完成的提示文本
   */
  submitTips?: string | ((data: T) => string);
  /**
   * 成功回调
   */
  onSuccess?: (res: any) => void;
}

/**
 * 编辑钩子
 * @param conf 配置
 */
export const useEditForm = <T, K = any, R = {}>(conf?: EditFormConfig<T, K, R>) => {
  const config: EditFormConfig<T, K, R> = {
    ...conf,
  };

  const formRef = ref<FormInstanceFunctions>();
  const onEdit = ref(false);
  const formData = ref<T>();
  formData.value = { ...config.default };
  const submitting = ref(false);

  /**
   * 前往编辑
   * @param data 待编辑的数据
   */
  const toEdit = (data?: K) => {
    if (data) {
      if (config.dataTransform) {
        formData.value = config.dataTransform(data);
      } else {
        formData.value = { ...cloneDeep(config.default), ...data };
      }
    } else {
      formData.value = { ...cloneDeep(config.default) };
    }

    onEdit.value = true;
  };

  /**
   * 提交
   * @param context 提交的上下文
   */
  const submit = (context: SubmitContext) => {
    if (!config.onSubmit) {
      return;
    }
    if (context.validateResult !== true) {
      return;
    }

    submitting.value = true;

    config
      .onSubmit(cloneDeep(formData.value))
      .then((res) => {
        let msg: string;
        if (typeof config.submitTips === 'string') {
          msg = config.submitTips;
        } else if (typeof config.submitTips === 'function') {
          msg = config.submitTips(cloneDeep(formData.value));
        }

        if (msg) {
          MessagePlugin.success(msg);
        }

        onEdit.value = false;

        if (config.onSuccess) {
          config.onSuccess(res);
        }
      })
      .finally(() => {
        submitting.value = false;
      });
  };

  return { formRef, onEdit, formData, formRules: config.formRules, submitting, toEdit, submit };
};
