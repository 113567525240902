<template>
  <t-config-provider :global-config="globalConfig">
    <router-view />
  </t-config-provider>
</template>
<script setup lang="ts">
import { base64Reg } from '@meerkat/common/pattern';
import { nanoid } from 'nanoid';
import { GlobalConfigProvider } from 'tdesign-vue-next';
import { onBeforeMount } from 'vue';

onBeforeMount(() => {
  window.name = `meerkat-${nanoid()}`;
});

const globalConfig: GlobalConfigProvider = {
  dialog: {
    closeOnOverlayClick: false,
  },
  drawer: {
    closeOnOverlayClick: false,
  },
  image: {
    loadingText: '',
    errorText: '',
    replaceImageSrc(params) {
      // 临时解决 params.src 可能会是 File 的问题
      if (typeof params.src !== 'string') {
        return params.src.name;
      }

      // 如果地址 已经包含样式 或者 非猫鼬云文件空间 则 返回原始地址
      if (
        /!((w\d{1,})|(original))$/.test(params.src) ||
        base64Reg.test(params.src) ||
        !params.src.includes('https://file.data-vib.com')
      ) {
        return params.src;
      }

      console.warn('当前页面存在图片未加样式', params.src);
      return `${params.src}!original`;
    },
  },
};
</script>
<style lang="less" scoped>
#nprogress .bar {
  background: var(--td-brand-color) !important;
}
</style>
