<template>
  <div>
    <t-layout key="side" class="t-layout--with-sider">
      <t-aside>
        <layout-side-nav :menu="menuRouters" :is-compact="settingStore.isSidebarCompact" />
      </t-aside>
      <t-layout>
        <t-header>
          <layout-header :is-compact="settingStore.isSidebarCompact" />
        </t-header>
        <t-content><layout-content /></t-content>
      </t-layout>
    </t-layout>

    <!-- custom 全屏加载 -->
    <t-loading :loading="general.fullLoading" :text="general.fullLoadingTip" fullscreen :delay="100" />
  </div>
</template>

<script setup lang="ts">
import '@/style/layout.less';

import { storeToRefs } from 'pinia';

import { useGeneralStore, usePermissionStore, useSettingStore } from '@/store';

import LayoutContent from './components/LayoutContent.vue';
import LayoutHeader from './components/LayoutHeader.vue';
import LayoutSideNav from './components/LayoutSideNav.vue';

const general = useGeneralStore();
const settingStore = useSettingStore();
const permissionStore = usePermissionStore();
const { menus: menuRouters } = storeToRefs(permissionStore);
</script>

<style lang="less" scoped></style>
