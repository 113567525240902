import { defineStore } from 'pinia';

import { BaseUserInfo, LoginParams, PhoneLoginParams } from '@/api/model/systemModel';
import { doLogin, doMobileLogin, getUserInfo } from '@/api/system';
import { TOKEN_NAME } from '@/config/global';
import { store } from '@/store';

interface UserStore {
  userInfo?: BaseUserInfo;
  token?: string;
  tenant?: string;
}

export const useUserStore = defineStore('user', {
  state: () => {
    const token = localStorage.getItem(TOKEN_NAME);
    const tenant = localStorage.getItem('tenant');
    const data: UserStore = {
      token: token || undefined,
      tenant: tenant || undefined,
      userInfo: undefined,
    };
    return data;
  },
  getters: {
    /**
     * 用户id
     * @param state 状态
     */
    id: (state) => {
      return state.userInfo.employee.id;
    },
    /**
     * 用户名
     * @param state 状态
     */
    name: (state) => {
      if (!state.userInfo || !state.userInfo.employee) {
        return '';
      }

      if (state.userInfo.employee.claimant) {
        return state.userInfo.employee.claimant;
      }
      if (state.userInfo.employee.realName) {
        return state.userInfo.employee.realName;
      }
      return state.userInfo.username;
    },
    /**
     * 权限组
     * @param state 状态
     */
    permissions: (state) => {
      if (!state.userInfo) {
        return [];
      }

      return state.userInfo.permissions;
    },
  },
  actions: {
    /**
     * 登录
     * @param params 参数
     */
    async login(params: LoginParams, complete?: () => void) {
      const response = await doLogin(params).finally(() => {
        if (complete) {
          complete();
        }
      });

      this.loginSuccess(response);
    },
    /**
     * 手机号验证码登录
     * @param params 参数
     */
    async phoneLogin(params: PhoneLoginParams, complete?: () => void) {
      const response = await doMobileLogin(params).finally(() => {
        if (complete) {
          complete();
        }
      });

      this.loginSuccess(response);
    },
    /**
     * 登录成功逻辑
     * @param loginRes 用户信息
     */
    loginSuccess(loginRes: string) {
      // this.token = loginRes.token;
      // this.tenant = loginRes.tenant;
      this.token = loginRes;
      localStorage.setItem(TOKEN_NAME, this.token);
      // localStorage.setItem('tenant', this.tenant);
      localStorage.setItem('tenant', '1');
      this.getUserInfo();
    },
    /**
     * 获取用户信息
     */
    async getUserInfo() {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            this.userInfo = res;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 退出登录
     */
    async logout() {
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem('tenant');
      localStorage.removeItem('tabRouterList');
      this.token = undefined;
      this.tenant = undefined;
      this.userInfo = undefined;
    },
    async removeToken() {
      this.token = '';
    },
  },
  persist: { key: 'user', paths: ['token'] },
});

export function getUserStore() {
  return useUserStore(store);
}
