import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/personal',
    name: 'Personal',
    component: Layout,
    redirect: '/platform/aiDigitalHuman',
    meta: { title: '个人版管理', icon: 'user-1', orderNo: 9 },
    children: [
      {
        path: 'methodology',
        name: 'PersonalMethodology',
        component: () => import('@/pages/personal/Methodology.vue'),
        meta: { title: '个人版黑盒' },
      },
      {
        path: 'methodologyEditor/:id',
        name: 'PersonalMethodologyEditor',
        component: () => import('@/pages/personal/MethodologyEditor.vue'),
        meta: { title: '个人版黑盒', hidden: true },
      },
      {
        path: 'methodologyStatistics',
        name: 'PersonalMethodologyStatistics',
        component: () => import('@/pages/personal/MethodologyStatistics.vue'),
        meta: { title: '个人版黑盒统计' },
      },
    ],
  },
];
