import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/customer',
    name: 'Customer',
    component: Layout,
    redirect: '/customer/user',
    meta: { title: '用户管理', icon: 'usergroup', orderNo: 1 },
    children: [
      {
        path: 'meerkatAI',
        name: 'CustomerMeerkatAI',
        component: () => import('@/pages/customer/MeerkatAI.vue'),
        meta: { title: 'MeerkatAI用户' },
      },
      {
        path: 'meerkatAIDetail/:id',
        name: 'CustomerMeerkatAIDetail',
        component: () => import('@/pages/customer/MeerkatAIDetail.vue'),
        meta: { title: 'MeerkatAI用户', hidden: true },
      },
      {
        path: 'personal',
        name: 'CustomerPersonal',
        component: () => import('@/pages/customer/Personal.vue'),
        meta: { title: '个人版用户' },
      },
    ],
  },
];
