import Blank from '@/layouts/blank.vue';
import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/platform',
    name: 'Platform',
    component: Layout,
    redirect: '/platform/aiDigitalHuman',
    meta: { title: 'Saas平台管理', icon: 'application', orderNo: 3 },
    children: [
      {
        path: 'aiDigitalHuman',
        name: 'PlatformAiDigitalHuman',
        component: () => import('@/pages/platform/AiDigitalHuman.vue'),
        meta: { title: 'AI数字人' },
      },
      {
        path: 'aiDigitalHumanEditor/:id',
        name: 'PlatformAiDigitalHumanEditor',
        component: () => import('@/pages/platform/AiDigitalHumanEditor.vue'),
        meta: { title: 'AI数字人', hidden: true },
      },
      {
        path: 'aiDigitalHumanDetail/:id',
        name: 'PlatformAiDigitalHumanDetail',
        component: () => import('@/pages/platform/AiDigitalHumanDetail.vue'),
        meta: { title: 'AI数字人', hidden: true },
      },
      {
        path: 'aiDigitalHumanKnowledgeBase/:id',
        name: 'PlatformAiDigitalHumanKnowledgeBase',
        component: () => import('@/pages/platform/AiDigitalHumanKnowledgeBase.vue'),
        meta: { title: 'AI数字人关联知识库', hidden: true },
      },
      {
        path: 'aiDigitalCategory',
        name: 'PlatformAiDigitalCategory',
        component: () => import('@/pages/platform/AiDigitalCategory.vue'),
        meta: { title: '专家分类' },
      },
      {
        path: 'modelTrim',
        name: 'PlatformModelTrim',
        component: () => import('@/pages/platform/ModelTrim.vue'),
        meta: { title: '模型微调' },
      },
      {
        path: 'chain',
        name: 'PlatformChain',
        component: () => import('@/pages/platform/Agent.vue'),
        meta: { title: 'Chain管理' },
      },
      {
        path: 'saveForm',
        name: 'PlatformSaveForm',
        component: () => import('@/pages/platform/SaveForm.vue'),
        meta: { title: '结构存储' },
      },
      {
        path: 'saveFormEditor/:id',
        name: 'PlatformSaveFormEditor',
        component: () => import('@/pages/platform/SaveFormEditor.vue'),
        meta: { title: '结构存储', hidden: true },
      },
      {
        path: 'Category',
        name: 'PlatformCategory',
        component: () => import('@/pages/platform/Category.vue'),
        meta: { title: '品类管理' },
      },
      {
        path: 'post',
        name: 'PlatformPost',
        component: () => import('@/pages/platform/Post.vue'),
        meta: { title: '职位管理' },
      },
      {
        path: 'hotDigitalHuman',
        name: 'PlatformHotDigitalHuman',
        component: () => import('@/pages/platform/HotDigitalHuman.vue'),
        meta: { title: '热门专家' },
      },
      {
        path: 'pptOutline',
        name: 'PlatformPPTOutline',
        component: () => import('@/pages/platform/PPTOutline.vue'),
        meta: { title: 'PPT大纲' },
      },
      {
        path: 'pptOutlineEditor/:id',
        name: 'PlatformPPTOutlineEditor',
        component: () => import('@/pages/platform/PPTOutlineEditor.vue'),
        meta: { title: 'PPT大纲', hidden: true },
      },
      {
        path: 'knowledgeBase',
        name: 'PlatformKnowledgeBase',
        component: () => import('@/pages/platform/KnowledgeBase.vue'),
        meta: { title: '知识库' },
      },
      {
        path: 'knowledgeBase/:type/:id/:folderId?',
        name: 'PlatformKnowledgeBaseEditor',
        component: () => import('@/pages/platform/KnowledgeBaseEditor.vue'),
        meta: { title: '知识库', hidden: true },
      },
      {
        path: 'knowledgeBase/:id',
        name: 'PlatformKnowledgeBaseDetail',
        component: () => import('@/pages/platform/KnowledgeBaseDetail.vue'),
        meta: { title: '知识库', hidden: true },
      },
    ],
  },
  {
    path: '/platform/chain',
    name: 'PlatformChainFull',
    component: Blank,
    children: [
      {
        path: 'editor/:id',
        name: 'PlatformChainEditor',
        component: () => import('@/pages/platform/AgentEditor.vue'),
        meta: { title: 'Chain - 流程', hidden: true },
      },
    ],
  },
];
