/**
 * 获取路径配置项
 */
export interface GetKeyPathConfig<T, P> {
  /**
   * 树数据
   */
  tree: T[];
  /**
   * 路径最终值
   */
  value: P;
  /**
   * 比对key
   */
  key: keyof T;
  /**
   * 孩子节点key
   */
  children: keyof T;
  /**
   * 值类型
   * key: 只返回key; object: 返回整个对象; index: 下标;
   */
  valueType?: 'key' | 'object' | 'index';
}

/**
 * 获取路径
 * @param config 参数
 */
export function getKeyPath<T, P>(config: GetKeyPathConfig<T, P>, path: (P | T | number)[] = []): (P | T | number)[] {
  const conf: GetKeyPathConfig<T, P> = { valueType: 'key', ...config };
  const { tree, value, key, children, valueType } = conf;
  let innerPath = path;
  for (const index in tree) {
    if (!Object.prototype.hasOwnProperty.call(tree, index)) {
      continue;
    }

    const item = tree[index];

    // 找到最终值
    if (item[key] === value) {
      if (valueType === 'key') {
        innerPath.push(item[key] as P);
      } else if (valueType === 'object') {
        innerPath.push(item);
      } else {
        innerPath.push(Number(index));
      }

      break;
    }

    // 未找到
    if (item[children] && (item[children] as T[]).length > 0) {
      let value: P | T | number;
      if (valueType === 'key') {
        value = item[key] as P;
      } else if (valueType === 'object') {
        value = item;
      } else {
        value = Number(index);
      }
      const newPath = getKeyPath<T, P>({ ...conf, tree: item[children] as T[] }, [...innerPath, value]);
      if (newPath.length > innerPath.length + 1) {
        innerPath = newPath;

        break;
      }
    }
  }

  return innerPath;
}
