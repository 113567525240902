import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/system',
    component: Layout,
    name: 'System',
    redirect: '/system/account',
    meta: { title: '系统设置', icon: 'setting', orderNo: 9999999999 },
    children: [
      {
        path: 'salesperson',
        name: 'SystemSalesperson',
        component: () => import('@/pages/system/Salesperson.vue'),
        meta: { title: '销售人员' },
      },
      {
        path: 'account',
        name: 'SystemAccount',
        component: () => import('@/pages/system/Account.vue'),
        meta: { title: '账户管理' },
      },
      {
        path: 'role',
        name: 'SystemRole',
        component: () => import('@/pages/system/Role.vue'),
        meta: { title: '角色管理' },
      },
    ],
  },
];
