import Blank from '@/layouts/blank.vue';
import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/target',
    name: 'Target',
    component: Layout,
    redirect: '/target/aiDigitalHuman',
    meta: { title: '产品靶场', icon: 'radar', orderNo: 3 },
    children: [
      {
        path: 'chain',
        name: 'TargetChain',
        component: () => import('@/pages/target/Agent.vue'),
        meta: { title: 'Chain管理' },
      },
    ],
  },
  {
    path: '/target/chain',
    name: 'TargetChainFull',
    component: Blank,
    children: [
      {
        path: 'editor/:id',
        name: 'TargetChainEditor',
        component: () => import('@/pages/platform/AgentEditor.vue'),
        meta: { title: 'Chain - 流程', hidden: true },
      },
    ],
  },
];
