<template>
  <div class="tdesign-wrapper">
    <router-view />
  </div>
</template>
<style lang="less" scoped>
.tdesign-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
